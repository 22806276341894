import React from 'react'
import logo from '../img/mark.png'

const HomeIntro = ({content}) => (<section className="intro has-text-centered columns">
  <div className="column is-10 is-offset-1">
    <img src={logo} alt="Joint Force Leadership" className="logo-small"/>
    <h1>{content.frontmatter.title}<span>.</span>
    </h1>
    <div className="spread-2 index-content" dangerouslySetInnerHTML={{
        __html: content.html
      }}/>

  </div>
</section>
)

export default HomeIntro
