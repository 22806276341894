import React from 'react'
import Link from 'gatsby-link'
import Truncate from 'react-truncate';
import Img from "gatsby-image";
import {StaticQuery, graphql} from 'gatsby';

export default () => (
  <StaticQuery query= {
    graphql `
      query BlogGridQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] },
          filter: { frontmatter: { templateKey: { eq: "blog-post" } }}
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                image {
                  childImageSharp {
                    fixed(width: 450, height: 100) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  }
  render={data => (
    <section>
    <div className="long-contain">
      <h2 className="has-text-weight-bold has-text-centered is-size-4 is-uppercase bot-1">From The Blog</h2>
      <div className="columns is-multiline">
        {
          data.allMarkdownRemark.edges.map(({node: post}) => (<div className="column is-4 blog-post-snippet" key={post.id}>
            <Link to={post.fields.slug}><Img className="post-img" fixed={post.frontmatter.image.childImageSharp.fixed}/></Link>
            <small>{post.frontmatter.date}</small>
            <div className="link-contain">
              <Link to={post.fields.slug}>
                <h4 className="has-text-primary is-size-4 no-pad">
                  {post.frontmatter.title}
                </h4>
              </Link>
              <Link className="arrow-float" to={post.fields.slug}>
                →
              </Link>
            </div>
            <p>
              <Truncate lines={4}>
                {post.excerpt}
              </Truncate><br/>

            </p>

          </div>))
        }
      </div>
    </div>
  </section>
  )}
  />
)
