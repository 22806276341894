import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import Img from "gatsby-image";
import { graphql } from 'gatsby'

//Components
import Hero from '../components/Hero'
import HomeIntro from '../components/Homeintro'
import CallToAction from '../components/CallToAction'
import System from '../components/System'
import Book from '../components/Book'
import BlogGrid from '../components/Bloggrid'
import ServicesGrid from '../components/Servicesgrid'
import ContactForm from '../components/ContactForm'

export default class IndexPage extends React.Component {
  render() {
    //Get Page Data
    const {data} = this.props
    // Parse Content for System Section

    const systemcontent = remark().use(recommended).use(remarkHtml).processSync(data.pagecontent.frontmatter.system).toString();
    return (<Layout>
      <div className="primary-contain">
        <Hero headerimg={data.headerimage.childImageSharp.fluid}/>
        <div className="secondary-contain">
            <HomeIntro content={data.pagecontent}/>
            <ServicesGrid/>
            <Book />
            <CallToAction/>
            <System content={systemcontent}/>
            <BlogGrid/>
          <ContactForm/>
        </div>
      </div>
    </Layout>)
  }
}
IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({edges: PropTypes.array})
  })
}

export const pageQuery = graphql ` query IndexQuery {

      headerimage: file(relativePath: {eq: "hero3.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1920, cropFocus: WEST) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      pagecontent: markdownRemark(frontmatter : {
        path: {
          eq: "/"
        }
      }) {
        id
        frontmatter {
          path
          title
          system
        }
        html
      }
    }


    `
