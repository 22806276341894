import React from 'react'
import Link from 'gatsby-link'

const System = ({content}) => (<section className="system">
  <div className="system-inner">
  <h2 className="has-text-weight-bold is-size-3">Our Leadership System</h2>
  <div className=" bot-1" dangerouslySetInnerHTML={{
      __html: content
    }}/>
  <Link className="button is-primary-invert" to="/our-system">More About Our System &rarr;</Link>
</div>
</section>)

export default System
