import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import Img from "gatsby-image";
import {StaticQuery, graphql} from 'gatsby';

export default() => (
  <StaticQuery query={
    graphql `
      query ServiceGridQuery {
        services: allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] },
          filter: { frontmatter: { templateKey: { eq: "service" } }}
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                image {
                  childImageSharp {
                    fluid(maxWidth : 500) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }

        bios: allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: { frontmatter: { path: { eq: "/bios" } }}
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                path
                image {
                  childImageSharp {
                    fixed(width : 150, height: 150) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              html
            }
          }
        }
      }

    `
  } render={data => (
    <section className="primary whatwedo">
    <div className="long-contain">
      <h2 className="has-text-weight-bold is-size-4 is-uppercase bot-1 text-center">What We Do</h2>
      <div className="columns is-multiline">
        {
          data.services.edges.map(({node: post}) => (<div className="column is-one-half-tablet is-one-third-desktop" key={post.id}>
            <Link className="service-snippet" to={post.fields.slug}><Img className="service-img" fluid={post.frontmatter.image.childImageSharp.fluid}/>
              <h4 className="is-size-4">
                {post.frontmatter.title}
                <div className="view-btn"> View More &rarr;</div>
              </h4>
              <div className="floatbox"></div>

            </Link>

          </div>))
        }
      </div>
    </div>
    <div className="long-contain">
      <h2 className="has-text-weight-bold is-size-4 is-uppercase down-1 text-center">Who We Are</h2>
      <div className="columns is-multiline whoweare">
        {
          data.bios.edges.map(({node: bio}) => (<div className="column is-one-half-tablet" key={bio.id}>
            <Link className="bio-snippet" to='/about#team'><Img className="bio-img" fixed={bio.frontmatter.image.childImageSharp.fixed}/>
              <h4 className="is-size-4">
                {bio.frontmatter.title} <br />
                <div className="view-btn"> View Bio &rarr;</div>
              </h4>
              <div className="floatbox"></div>

            </Link>

          </div>))
        }
      </div>
    </div>
  </section>

  )} />)
