import React from 'react'
import bookcover from '../img/bookcover.jpg'

const Book = ({content}) => (<section className="book">
  <div className="book-inner columns spread-2">
    <div className="row">
  <img src={bookcover} alt="Joint Force Leadership Book" className="bookcover"/>
  <h2 className="has-text-weight-bold is-size-3">Get Our Book</h2>
  <p>Learn leadership tools and skills Navy SEALs and Air Force Fighter Pilots leverage to win on the ground, in the air, and in the fast-paced world of modern business.</p>
  <p>Navy SEALs lead on the ground. Fighter pilots lead in the air. Together, they can lead anyone, anywhere. Joint Force Leadership brings together Jim “Boots” Demarest and Mark McGinnis’s combined fifty years of military and business experience, and presents battlefield and boardroom tested tools and skills that will resonate with business people, travelers, and military enthusiasts alike.</p>
  <a href="https://www.amazon.com/Joint-Force-Leadership-Fighter-Success/dp/1642934836/" className="button" target="_blank"><i class="fab fa-amazon"></i>&nbsp;Buy On Amazon</a>
  </div>
</div>
</section>)

export default Book
