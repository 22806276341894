import React from 'react'
import CtaForm from '../components/CtaForm'

const CallToAction = ({content}) => (
<section className="leadership-guide-section">
<div className="columns">
  <div>
  <h2 className="is-size-3 text-center">Leadership Guide</h2>
  <p> Ready to take your leadership to the next level?  Sign up below to get our free essential elements of leadership guide and get started on your leadership journey:</p>
  </div>
</div>
<div className="columns has-text-centered">
  <CtaForm />
</div>
</section>
)

export default CallToAction
